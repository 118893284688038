import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import CommentsBox from './CommentsBox'

const formatDate = (date) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const dateString = new Date(date).toLocaleDateString('en-US', options)
  const timeString = new Date(date).toLocaleTimeString('en-US')

  return dateString + ' at ' + timeString
}

const generateCommentThread = (parent, child, level) => {
  let thread_level = level + 1
  let parent_aux = [...parent]
  let child_aux = [...child]

  parent.forEach((parent_comment) => {
    child.forEach((child_comment) => {
      if (child_comment.parent_wordpress_id === parent_comment.wordpress_id) {
        if (
          child_comment.main_parent_wordpress_id === undefined &&
          parent_comment.main_parent_wordpress_id === undefined
        ) {
          child_comment.main_parent_wordpress_id = parent_comment.wordpress_id
        } else if (
          child_comment.main_parent_wordpress_id === undefined &&
          parent_comment.main_parent_wordpress_id !== undefined
        ) {
          child_comment.main_parent_wordpress_id =
            parent_comment.main_parent_wordpress_id
        }
        parent_aux.push({ ...child_comment, thread_level })
        child_aux = child_aux.filter(
          (child_aux_comment) =>
            child_aux_comment.parent_wordpress_id !==
            child_comment.parent_wordpress_id
        )
      }
    })
  })

  if (child_aux.length) {
    return generateCommentThread(parent_aux, child_aux, thread_level)
  } else {
    return organizeThreadedComments(parent_aux)
  }
}

const organizeThreadedComments = (source) => {
  const main_parent_comments = source.filter(
    (comment) => comment.thread_level === 0
  )
  const children_comments = source
    .filter((comment) => comment.thread_level !== 0)
    .sort((a, b) => {
      return new Date(a.date) > new Date(b.date) ? 1 : -1
    })
  let organized_comments = []

  main_parent_comments.forEach((main_parent_comment) => {
    const children_comments_aux = children_comments.filter(
      (children_comment) =>
        children_comment.main_parent_wordpress_id ===
        main_parent_comment.wordpress_id
    )

    organized_comments = organized_comments
      .concat([main_parent_comment])
      .concat(children_comments_aux)
  })

  return organized_comments
}

const generateComments = (comments, postId, parentId, changeParentId) => {
  const parentComments = comments
    .filter(({ node: comment }) => comment.parent_element === null)
    .reverse() // so it's from oldest to newewst
    .map(({ node: comment }) => ({
      ...comment,
      thread_level: 0,
      parent_wordpress_id: null,
    }))

  const childComments = comments
    .filter(({ node: comment }) => comment.parent_element !== null)
    .reverse()
    .map(({ node: comment }) => ({
      ...comment,
      parent_wordpress_id: comment.parent_element.wordpress_id,
    }))

  const threadedComments = generateCommentThread(
    parentComments,
    childComments,
    0
  )

  return threadedComments.map((comment, index) => (
    <div
      className="flex-col-12 comment"
      key={index}
      data-level={comment.thread_level}
      css={css`
        margin-left: ${comment.thread_level * 2}%;
      `}
    >
      <div className="comment-wrapper">
        {comment.thread_level > 0 ? (
          <div className="comment-reply">
            in response to{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: comment.parent_element.author_name,
              }}
            />
          </div>
        ) : null}
        <div className="header">
          <div className="left-side">
            <FontAwesomeIcon icon={faUserCircle} />
          </div>
          <div className="right-side">
            <h3
              className="author"
              dangerouslySetInnerHTML={{
                __html: comment.author_name,
              }}
            />
            <span className="date">{formatDate(comment.date)}</span>
          </div>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: comment.content,
          }}
        ></div>
        <button
          type="button"
          className="button-reply"
          onClick={() => changeParentId(comment.wordpress_id)}
        >
          Reply
        </button>
        {parentId === comment.wordpress_id ? (
          <CommentsBox
            author={comment.author_name}
            postId={postId}
            parentId={parentId}
            changeParentId={changeParentId}
          />
        ) : null}
      </div>
    </div>
  ))
}

const CommentsSection = styled('section')`
  font-family: 'Roboto', sans-serif;

  .title {
    font-size: 30px;
    color: #a5110e;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;
  }

  .header {
    display: flex;
    color: #848484;
    margin-bottom: 20px;
  }

  .left-side {
    margin-right: 15px;
  }

  svg {
    font-size: 37px;
  }

  .author {
    text-transform: uppercase;
    margin: 0 0 5px;
  }

  p {
    font-size: 16px;
    line-height: 28.8px;
    text-indent: 0;
    margin: 0 0 26px 0;
    color: #848484;
  }

  .comment {
    margin-bottom: 20px;
  }

  .comment-wrapper {
    padding: 10px 0;
    border-top: 5px solid #3a3a3a;
  }

  .comment-reply {
    background: #636363;
    color: #fff;
    padding: 6px 9px;
    margin-bottom: 10px;
    display: inline-block;

    span {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .button-reply {
    border: none;
    background: none;
    color: #a18c78;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 19px;
    padding: 0;
    outline: none;
    position: relative;

    &:hover {
      color: #e26b63;
    }
    &:active {
      top: 1px;
    }
  }
`

const Comment = ({ comments, postId }) => {
  const [parentId, changeParentId] = useState(0)

  return (
    <>
      {comments.length ? (
        <CommentsSection>
          <div className="container">
            <div className="flex-row">
              <div className="flex-col-12">
                <h2 className="title">
                  {comments.length} Comment{comments.length !== 1 ? 's' : null}
                </h2>
              </div>
              {generateComments(comments, postId, parentId, changeParentId)}
            </div>
          </div>
        </CommentsSection>
      ) : null}

      {parentId === 0 ? (
        <CommentsBox
          postId={postId}
          parentId={parentId}
          changeParentId={changeParentId}
        />
      ) : null}
    </>
  )
}

export default Comment
