import React from 'react'
import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'

const parseAIOSEO = (head_tags) => {
  const head_obj = {
    meta: {},
    link: {},
    script: {},
  }

  if (head_tags === null) return head_obj

  head_tags = head_tags

  // description and keywords
  const head_basic = head_tags
    .map(({ attributes }) => attributes)
    .filter(
      (head_tag) =>
        head_tag.name &&
        !head_tag.name.includes('msapplication') &&
        !head_tag.name.includes('theme-color') &&
        !head_tag.name.includes('twitter') &&
        (head_tag.name === 'keywords' || head_tag.name === 'description')
    )
    .map((meta_tag) => {
      head_obj.meta[meta_tag.name] = meta_tag.content
        ? meta_tag.content.trim()
        : ''
      return `<meta name="${meta_tag.name}" content="${meta_tag.content}">`
    })

  // twitter
  const head_twitter = head_tags
    .map(({ attributes }) => attributes)
    .filter((head_tag) => head_tag.name && head_tag.name.includes('twitter'))
    .map((meta_tag) => {
      head_obj.meta[meta_tag.name] = meta_tag.content
        ? meta_tag.content.trim()
        : ''
      return `<meta name="${meta_tag.name}" content="${meta_tag.content}">`
    })

  // opengraph
  const head_og = head_tags
    .map(({ attributes }) => attributes)
    .filter(
      (head_tag) => head_tag.property && head_tag.property.includes('og:')
    )
    .map((meta_tag) => {
      head_obj.meta[meta_tag.property] = meta_tag.content
        ? meta_tag.content.trim()
        : ''
      return `<meta property="${meta_tag.property}" content="${meta_tag.content}">`
    })

  // link canonical
  const head_canonical = head_tags
    .map(({ attributes }) => attributes)
    .filter((head_tag) => head_tag.rel && head_tag.rel.includes('canonical'))
    .map((meta_tag) => {
      head_obj.link[meta_tag.rel] = meta_tag.href ? meta_tag.href.trim() : ''
      return `<link rel="${meta_tag.rel}" href="${meta_tag.href}">`
    })

  // application/ld+json
  const head_ld_json = head_tags
    .filter(
      (head_tag) =>
        head_tag.attributes.type &&
        head_tag.attributes.type.includes('application/ld+json')
    )
    .map((meta_tag) => {
      head_obj.script[meta_tag.attributes.type] = meta_tag.content
        ? meta_tag.content.trim()
        : ''
      return `<script type="${meta_tag.attributes.type}" class="class="aioseop-schema"">${meta_tag.content}</script>`
    })

  return head_obj
}

const parseBodyClass = (post_pathname) => {
  return post_pathname.replace(/blog|\//g, '')
}

const PostSEO = ({ title, head }) => {
  const head_obj = parseAIOSEO(head)
  const { pathname } = useLocation()

  return (
    <Helmet title={title} bodyAttributes={{ class: parseBodyClass(pathname) }}>
      {head_obj.meta.description && (
        <meta name="description" content={head_obj.meta.description} />
      )}
      {head_obj.meta.keywords && (
        <meta name="keywords" content={head_obj.meta.keywords} />
      )}
      {head_obj.meta['twitter:card'] && (
        <meta name="twitter:card" content={head_obj.meta['twitter:card']} />
      )}
      {head_obj.meta['twitter:title'] && (
        <meta name="twitter:title" content={head_obj.meta['twitter:title']} />
      )}
      {head_obj.meta['twitter:description'] && (
        <meta
          name="twitter:description"
          content={head_obj.meta['twitter:description']}
        />
      )}
      {head_obj.meta['twitter:image'] && (
        <meta name="twitter:image" content={head_obj.meta['twitter:image']} />
      )}
      {head_obj.meta['og:type'] && (
        <meta property="og:type" content={head_obj.meta['og:type']} />
      )}
      {head_obj.meta['og:title'] && (
        <meta property="og:title" content={head_obj.meta['og:title']} />
      )}
      {head_obj.meta['og:description'] && (
        <meta
          property="og:description"
          content={head_obj.meta['og:description']}
        />
      )}
      {head_obj.meta['og:url'] && (
        <meta
          property="og:url"
          content={head_obj.meta['og:url'].replace(
            'wp.refactortactical.com',
            'blog.refactortactical.com'
          )}
        />
      )}
      {head_obj.meta['og:site_name'] && (
        <meta property="og:site_name" content={head_obj.meta['og:site_name']} />
      )}
      {head_obj.meta['og:image'] && (
        <meta property="og:image" content={head_obj.meta['og:image']} />
      )}
      {head_obj.meta['og:image:secure_url'] && (
        <meta
          property="og:image:secure_url"
          content={head_obj.meta['og:image:secure_url']}
        />
      )}
      {head_obj.link.canonical && (
        <link
          rel="canonical"
          href={head_obj.link.canonical.replace(
            'wp.refactortactical.com',
            'blog.refactortactical.com'
          )}
        />
      )}
      {head_obj.script['application/ld+json'] && (
        <script type="application/ld+json">
          {head_obj.script['application/ld+json']}
        </script>
      )}
    </Helmet>
  )
}

export default PostSEO
