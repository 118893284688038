import React from 'react'
import styled from '@emotion/styled'
import AdGroupHandler from './AdGroupHandler'

const BlogDODWrapper = styled('div')`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: xx-large;
  font-weight: 400;
  border: medium;
  border-color: #a18c78;
  overflow: hidden;
  background-color: rgb(226, 222, 222);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 30px;

  @media (max-width: 767px) {
    height: 700px;
  }
`

const BlogDOD = (props) => (
  <BlogDODWrapper>
    <div>Deals of the day</div>
    <AdGroupHandler style={props.style} blog="blog" />
  </BlogDODWrapper>
)

export default BlogDOD
