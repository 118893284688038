import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify'

const CommentsBoxWrapper = styled('div')`
  font-family: 'Roboto', sans-serif;
  margin-top: 20px;

  .comment-inner {
    position: relative;
    padding: 30px 40px;
    background: #f5f5f5;
  }

  h2 {
    font-size: 2rem;
    margin: 0;
    color: #525252;
    text-transform: uppercase;

    & + p {
      margin: 10px 0 25px;
      font-size: 0.9rem;
    }
  }

  .required {
    font-weight: bold;
    color: #a18c78;
  }

  .form-row {
    margin: 0 -15px;
  }

  .form-row,
  .form-elem {
    display: flex;
  }

  .top {
    margin-bottom: 25px;
    .form-elem {
      width: 100%;
    }
  }

  .bottom {
    .form-elem {
      width: 33.33%;
    }
  }

  .form-elem {
    padding: 0 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #b9b8bb;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 7px;
    color: #adadad;
  }

  textarea {
    height: 150px;
  }

  input {
    height: 50px;
  }

  .msg-wrapper {
    width: 100%;
  }

  .msg {
    width: 100%;
    margin: 15px 0 0;
    padding: 10px;
    color: #fff;
    background: transparent;
    border-radius: 2px;

    ul {
      margin: 0;
      padding-left: 25px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &.error {
      background: #f53030;
    }

    &.success {
      background: #28a745;
    }
  }

  button {
    border: none;
    cursor: pointer;
    background: #a5110e;
    color: #fff;

    &:hover {
      background: #c44845;
    }
  }

  .button-close {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    background: #a18c78;
    top: 20px;

    svg {
      font-size: 1rem;
    }
  }

  .button-submit {
    border: none;
    cursor: pointer;
    background: #a5110e;
    color: #fff;
    padding: 12px 15px;
    display: inline-block;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 7px;

    &[disabled] {
      background: grey;
      cursor: not-allowed;
    }
  }

  @media (max-width: 767px) {
    .top {
      margin-bottom: 15px;
    }
    .bottom {
      flex-direction: column;

      .form-elem {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .comment-wrapper {
      padding: 20px 30px;
    }

    h2 {
      font-size: 1.6rem;
    }

    label,
    .msg {
      font-size: 0.95rem;
    }

    .msg {
      margin-top: 5px;
    }
  }
`

const handleFormComments = (event, formState, changeFormState) => {
  event.preventDefault()
  const [postId, parentId, comment, author, email, url] = event.target.elements
  const FETCH_URL = `https://wp.refactortactical.com/wp-json/wp/v2/comments`
  let formResult = {
    formIsSubmitting: true,
    formErrorMessage: '<ul>',
  }

  changeFormState({
    ...formState,
    formIsSubmitting: true,
  })

  const data = {
    post: DOMPurify.sanitize(postId.value.trim()),
    parent: DOMPurify.sanitize(parentId.value.trim()),
    author_name: DOMPurify.sanitize(author.value.trim()),
    author_url: DOMPurify.sanitize(url.value.trim()),
    author_email: DOMPurify.sanitize(email.value.trim()),
    content: DOMPurify.sanitize(comment.value.trim()),
  }

  // required fields: (comment, name and email)

  // Comment
  if (data.content === '') {
    formResult.formErrorMessage += '<li>The Comment field is required</li>'
  } else if (data.content.length < 10) {
    formResult.formErrorMessage +=
      '<li>The Comment field needs to have at least 10 characters long</li>'
  }

  // Name
  if (data.author_name === '') {
    formResult.formErrorMessage += '<li>The Name field is required</li>'
  } else if (data.author_name.length < 2) {
    formResult.formErrorMessage +=
      '<li>The Name field needs to have at least 2 characters long</li>'
  }

  // Email
  if (data.author_email === '') {
    formResult.formErrorMessage += '<li>The Email field is required</li>'
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.author_email)) {
    formResult.formErrorMessage += '<li>Please enter a valid email</li>'
  }

  // Error Message
  if (formResult.formErrorMessage.includes('li')) {
    formResult.formErrorMessage += '</ul>'
    changeFormState({
      ...formState,
      formErrorMessage: formResult.formErrorMessage,
      formIsSubmitting: false,
    })

    return
  } else {
    formResult.formErrorMessage = null
  }

  fetch(FETCH_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok === true) {
        changeFormState({
          ...formState,
          ...formResult,
          formIsSubmitting: false,
          formSubmittedSuccessfully: true,
        })
      }

      return response.json()
    })
    .then((object) => {
      if (object.message) {
        changeFormState({
          ...formState,
          ...formResult,
          formIsSubmitting: false,
          formSubmittedFailed: true,
          formErrorMessage: object.message,
        })
      }
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.error('Error:', error)
    })
}

const CommentBox = ({ author, postId, parentId, changeParentId }) => {
  const [formState, changeFormState] = useState({
    formIsSubmitting: false,
    formSubmittedSuccessfully: false,
    formSubmittedFailed: false,
    formErrorMessage: null,
  })

  return (
    <CommentsBoxWrapper>
      <div className="container">
        <div className="flex-row">
          <div className="flex-col-12">
            <div className="comment-inner">
              {parentId !== 0 ? (
                <>
                  <button
                    type="button"
                    className="button-close"
                    onClick={() => changeParentId(0)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                  <h2>Reply to {author.toUpperCase()}</h2>
                </>
              ) : (
                <h2>Leave a Reply</h2>
              )}
              <p>
                Your email address will not be published. Required fields are
                marked <span className="required">*</span>
              </p>
              <form
                onSubmit={(event) =>
                  handleFormComments(event, formState, changeFormState)
                }
              >
                {formState.formSubmittedSuccessfully === false ? (
                  <>
                    {' '}
                    <input type="hidden" id="postId" value={postId} />
                    <input type="hidden" id="parentId" value={parentId} />
                    <div className="form-row top">
                      <div className="form-elem">
                        <label htmlFor="comment">
                          Comment <span className="required">*</span>
                        </label>
                        <textarea
                          id="comment"
                          name="comment"
                          disabled={formState.formIsSubmitting}
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row bottom">
                      <div className="form-elem">
                        <label htmlFor="author">
                          Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          id="author"
                          name="author"
                          disabled={formState.formIsSubmitting}
                        />
                      </div>
                      <div className="form-elem">
                        <label htmlFor="email">
                          Email <span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          disabled={formState.formIsSubmitting}
                        />
                      </div>
                      <div className="form-elem">
                        <label htmlFor="url">Website</label>
                        <input
                          type="text"
                          id="url"
                          name="url"
                          disabled={formState.formIsSubmitting}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-row">
                  <div className="form-elem msg-wrapper">
                    <div
                      className={`msg ${
                        formState.formErrorMessage !== null
                          ? 'error'
                          : formState.formSubmittedSuccessfully
                          ? 'success'
                          : ''
                      }`}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: `${
                            formState.formErrorMessage !== null
                              ? formState.formErrorMessage
                              : formState.formSubmittedSuccessfully
                              ? 'Your comment was submitted succesfully'
                              : ''
                          }`,
                        }}
                      />
                    </div>
                  </div>
                </div>

                {formState.formSubmittedSuccessfully === false ? (
                  <div className="form-row">
                    <div className="form-elem">
                      <button
                        type="submit"
                        className="button-submit"
                        disabled={formState.formIsSubmitting}
                      >
                        {formState.formIsSubmitting === false
                          ? 'Post Comment'
                          : 'Sending...'}
                      </button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </CommentsBoxWrapper>
  )
}

export default CommentBox
