import React from 'react'
import styled from '@emotion/styled'
import { decodeHtmlEntity } from '../helper/helper'
import { Link } from '@reach/router'

const convertToWidget = (shortCodeString) => {
  let component

  const matchedElem =
    shortCodeString
      .replace(/\r?\n|\r/g, '') // remove line breaks/
      .replace(/\s/g, ' ') // making spaces in any format to regular spaces
      .replace(/<br \/>/g, '') // remove line breaks/
      .replace(/<br\/>/g, '') // remove line breaks/
      .match(
        /\[\s*useful_resources[^\]]*\](.*?)\[\s*\/\s*useful_resources\]/gi
      ) || []

  if (matchedElem.length) {
    const matchedLink = matchedElem[0].match(/\[\s*link[^\]]*\]/gi) || []

    component = matchedLink.length
      ? matchedLink.map((link, index) => {
          link = decodeHtmlEntity(link).replace(
            /"|”|“|″|&#8221;|&#8243;/gi,
            '"'
          )

          const linkTitle = (link.match(/title="(.*?)"/gi)[0] || '')
            .replace('title=', '')
            .slice(1, -1)
          const linkHref = (link.match(/href="(.*?)"/gi)[0] || '')
            .replace('href=', '')
            .slice(1, -1)
            .replace('https://blog.refactortactical.com', '')

          return (
            <li key={index}>
              <Link to={linkHref}>{linkTitle}</Link>
            </li>
          )
        })
      : null
  } else {
    component = null
  }

  return component
}

const UsefulResources = styled('div')`
  border: 1px solid #00364b;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;

  & + .toc-container ul {
    max-height: calc(60vh - 45px);
  }

  h1 {
    background: #00364b;
    color: #fff;
    font-weight: 300;
    margin: 0;
    text-align: left;
    padding: 12px 20px;
    text-transform: uppercase;
    font-size: 21px;
    position: relative;

    &::after {
      position: absolute;
      content: '\f0ad';
      font-family: 'Font Awesome 5 Free';
      font-weight: bold;
      top: 0;
      right: 15px;
      display: flex;
      height: 100%;
      align-items: center;
      font-size: 16px;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    font-size: 14px;
    padding: 5px 20px 5px 5px;
    max-height: calc(25vh - 45px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #b7bbb5;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #0a0a0a;
    }
  }

  li {
    line-height: 1.4;
    padding: 5px 0 5px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  a {
    color: #3691ce;
    text-decoration: none;
    position: relative;
    display: flex;
    padding-right: 20px;

    &:hover {
      color: #000;
    }

    &::after {
      content: '\f35d';
      font-family: 'Font Awesome 5 Free';
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`

const WidgetUsefulResources = ({ postContent }) => {
  const usefulResourcesLiElements = convertToWidget(postContent)
  return usefulResourcesLiElements ? (
    <UsefulResources>
      <h1>Useful Resources</h1>
      <ul>{usefulResourcesLiElements}</ul>
    </UsefulResources>
  ) : null
}

export default WidgetUsefulResources
