import React from 'react'
import styled from '@emotion/styled'
import { decodeHtmlEntity } from '../helper/helper'

const convertToWidget = (shortCodeString) => {
  let component

  const matchedElem =
    shortCodeString
      .replace(/\r?\n|\r/g, '') // remove line breaks/
      .replace(/<br \/>/g, '') // remove line breaks/
      .replace(/<br\/>/g, '') // remove line breaks/
      .match(/\[\s*article_summary[^\]]*\]/gi) || []

  if (matchedElem.length) {
    const attrPairArray =
      decodeHtmlEntity(matchedElem[0]).match(
        /[a-z_]+=("|”|“|″)(.*?)("|”|“|″)/gi
      ) || []

    component = attrPairArray.length
      ? attrPairArray.map((attrPair, index) => {
          let title
          let attrTitle = decodeHtmlEntity(attrPair)
            .match(/[a-z_]+=/gi)[0]
            .slice(0, -1)
          let attrValue = decodeHtmlEntity(attrPair)
            .match(/=("|”|“|″)(.*?)("|”|“|″)/gi)[0]
            .slice(0, -1)
            .replace(/(="|=”|=″|=“)/g, '')

          switch (attrTitle) {
            case 'hours':
              title = 'Hours of Research'
              break
            case 'sources':
              title = 'Sources Used'
              break
            case 'companies':
              title = 'Companies Vetted'
              break
            case 'features':
              title = 'Features Reviewed'
              break
            case 'top_picks':
              title = 'Top Picks'
              break
          }

          return (
            <li key={index}>
              <span className="count">{attrValue}</span>
              <span className="title">{title}</span>
            </li>
          )
        })
      : null
  }

  return component
}

const ArticleSummary = styled('ul')`
  display: flex;
  padding: 0 0 10px;
  margin: 0;
  list-style-type: none;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 30%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #f1f4f0, #f7f8f7);
    border-top: 1px solid #c1c1c1;
  }

  span {
    display: block;
    position: relative;
    z-index: 1;
  }

  li  {
    color: #6e6e6e;
    margin: 0;
    width: 20%;
    text-align: center;
    padding: 0 10px;
  }

  .count {
    font-size: 22px;
    width: 80px;
    height: 80px;
    margin: auto;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #fff;
  }

  .title {
    font-size: 15px;
    line-height: 1.4;
    margin-top: 10px;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    .count {
      font-size: 18px;
      width: 70px;
      height: 70px;
    }
    .title {
      font-size: 14px;
    }
  }
  @media (max-width: 480px) {
    .count {
      font-size: 14px;
      width: 45px;
      height: 45px;
    }
    .title {
      font-size: 9px;
    }
  }
`

// const textShortCode = `
// [article_summary]
// 	[hours]200+[/hours]
// 	[sources]34+[/sources]
// 	[companies]17[/companies]
// 	[features]4[/features]
// 	[top_picks]10[/top_picks]
// [/article_summary]
// `

// replace(/\s/gi, "").match(/<\s*article_summary[^>]*>(.*?)<\s*\/\s*article_summary>/gi)
// replace(/\s/gi, "").match(/\[\s*article_summary[^\]]*\](.*?)\[\s*\/\s*article_summary\]/gi)

const WidgetArticleSummary = ({ postContent }) => {
  const articleLiElements = convertToWidget(postContent)
  return articleLiElements ? (
    <ArticleSummary>{articleLiElements}</ArticleSummary>
  ) : null
}

export default WidgetArticleSummary
