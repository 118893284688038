import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'
import styled from '@emotion/styled'
import { decodeHtmlEntity } from '../helper/helper'
import BlogDOD from './BlogDOD'

const sanitizeString = (contentString) => {
  return decodeHtmlEntity(contentString)
    .replace(/&#8217;/gi, "'")
    .replace(/&#8221;|&#8243;/gi, '"')
    .replace(/&#8211;/g, '-')
}

const HomeFW = styled('div')`
  background: #f8f8f8;
  border: solid 2px #357ae2;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  margin: 3rem 0;
  text-decoration: none;
  position: relative;
  .home-fw-margin {
    margin-bottom: 0px;
  }

  .fw-title {
    margin-top: 20px;
    margin-bottom: 20px;

    a {
      text-transform: uppercase;
    }
  }

  .white-bg {
    background-color: white;
  }

  .fw-flex {
    display: flex;
  }

  .fw-price {
    width: 50%;
    font-family: sans-serif;
    font-size: xx-large;
    font-weight: 900;

    @media (max-width: 480px) {
      font-size: 26px;

      .vendor {
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }

  .disclaimer {
    margin-top: 20px;
    font-size: medium;
  }

  .fw-button {
    width: 50%;
    justify-content: right;
    padding-top: 10px;
    text-align: right;
    color: #fff;
    display: block;
  }

  .vendor {
    font-size: large;
    font-family: sans-serif;
    font-weight: 400;
  }

  @media (max-width: 480px) {
    .fw-title {
      a {
        font-size: 20px;
      }
    }
    .fw-button {
      width: 100%;
      margin: 8px 0;
      text-align: left;
    }
    .fw-flex {
      flex-direction: column;
    }
  }
`

const Filter = ({ content }) => {
  let random = Math.random()

  if (!content.image && !content.VWtype && !content.DODcollection) {
    return (
      <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: content }}
        key={random}
      />
    )
  }

  if (content.image) {
    let contentImage =
      'https://wp.refactortactical.com/wp-content/uploads/2022/05/placeholder.png'
    let contentCost = ''
    let contentURL = ''
    let contentTitle = ''
    let contentVendor = ''

    if (content.image?.length > 1) {
      contentImage = content.image[1].slice(1, -1)
    }
    if (content.cost?.length > 1) {
      contentCost = content.cost[1].slice(1, -1)
    }
    if (content.url?.length > 1) {
      contentURL = content.url[1].slice(1, -1)
    }
    if (content.title?.length > 1) {
      contentTitle = sanitizeString(content.title[1].slice(1, -1))
    }
    if (content.vendor?.length > 2) {
      contentVendor = sanitizeString(content.vendor[2].trim())
    }

    const widgetImage = contentImage
    const widgetCost = contentCost
    const widgetUrl = contentURL
    let widgetTitle = contentTitle
    const vendor = contentVendor

    return (
      <HomeFW className="home-fw" key={random}>
        <div className="white-bg">
          <OutboundLink href={widgetUrl} target="_blank">
            <img className="homeFW-image" src={widgetImage} />
          </OutboundLink>
        </div>
        <div>
          <h3 className="fw-title">
            <OutboundLink
              href={widgetUrl}
              target="_blank"
              className="featureTitle"
            >
              {widgetTitle}
            </OutboundLink>
          </h3>
          <div className="fw-flex">
            <div className="fw-price">
              <div>{widgetCost}</div>
              <div className="vendor">at {vendor}</div>
            </div>

            <div className="fw-button">
              <OutboundLink
                href={widgetUrl}
                target="_blank"
                className="home-fw-button"
                name="FWbutton"
              >
                VIEW DETAILS
              </OutboundLink>
            </div>
          </div>
          <div className="disclaimer">Prices accurate at time of writing</div>
        </div>
      </HomeFW>
    )
  }

  if (content.VWtype) {
    let contentHref = '#'
    let contentTitle = ''

    if (content.VWhref?.length > 1) {
      contentHref = content.VWhref[1].slice(1, -1)
    }
    if (content.VWtitle?.length > 1) {
      contentTitle = content.VWtitle[1].slice(1, -1)
    }

    const widgetHref = contentHref
    const widgetTitle = contentTitle.toUpperCase()

    return (
      <OutboundLink href={widgetHref} target="_blank" className="visit-button">
        {widgetTitle} <i className="fas fa-external-link-alt"></i>
      </OutboundLink>
    )
  }
  if (content.DODcollection) {
    const DOD = content.DODcollection[1].slice(1, -1)
    return <BlogDOD style={DOD} />
  }
  return null
}

export default Filter
