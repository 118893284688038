import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import Layout from '../components/Layout'
import Comments from '../components/Comments'
import { navigate } from '@reach/router'
import useSiteMetadata from '../hooks/use-site-metadata'
import PostSEO from '../components/PostSEO'
import AdGroupHandler from '../components/AdGroupHandler'
import AdGroup from '../components/AdGroup'
import WidgetArticleSummary from '../components/WidgetArticleSummary'
import WidgetUsefulResources from '../components/WidgetUsefulResources'
import ShortcodeHandler from '../components/ShortcodeHandler'
import PostContext from '../components/PostContext'
import Filter from '../components/Filter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons'

const PostTemplate = styled('section')`
  .right-wrapper {
    //position: sticky;
    //top: 15px;
  }
  .klaviyo-form-newsletter-banner {
    padding: 5px 0;
    margin: 15px 0 20px;
    background: #4a4949;

    .container {
      display: flex;
      color: #fff;
      align-items: center;

      & > div {
        &:first-of-type {
          margin-right: 15px;
        }
      }

      .klaviyo-form {
        width: 46%;
      }
    }

    p {
      font-size: 15px;
      font-family: 'Menlo Regular', sans-serif;
      line-height: 1.2;
      margin: 0;
    }

    @media (max-width: 640px) {
      padding: 10px 0 5px;

      .container {
        text-align: center;
        flex-direction: column;

        & > div {
          width: 100% !important;
        }
      }

      p {
        font-size: 13px;
      }
    }
  }

  .recent-posts-wrapper {
    h3 {
      text-transform: uppercase;
      font-size: 23px;
      margin: 36px 0 10px;
    }

    ul {
      font-size: 16px;
      margin: 0;
      padding: 0 0 0 10px;
      list-style-type: none;

      a {
        color: inherit;
        text-decoration-thickness: 2px;
        text-decoration-skip-ink: none;
        text-decoration-color: #a18c78;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .toc-container {
    margin-top: 10px;
    color: #fff;
    text-align: right;
    border: 1px solid #f3f5f2;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    // Sticky
    //position: sticky;
    //top: 15px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .toc-inner {
      transition: transform 0.3s;
    }

    h2 {
      background: #f3f5f2;
      color: #3a3a3a;
      font-weight: 300;
      margin: 0;
      text-align: left;
      padding: 12px 20px;
      text-transform: uppercase;
      font-size: 21px;
    }

    ul {
      text-align: left;
      list-style-type: none;
      margin: 0;
      padding: 5px 20px 5px 5px;
      background: #fff;
      //max-height: 70vh;
      //overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #b7bbb5;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #0a0a0a;
      }

      li {
        cursor: pointer;
        color: #3691ce;
        padding: 5px 0;
        font-size: 15px;

        &[data-note-name='h2'],
        &[data-note-name='h3'],
        &[data-note-name='h4'] {
          display: flex;

          &::before {
            content: '>';
            margin-right: 5px;
          }
        }

        &[data-note-name='h2'] {
          padding-left: 15px;
          font-size: 14px;
        }

        &[data-note-name='h3'] {
          padding-left: 30px;
          font-size: 13px;
        }

        &[data-note-name='h4'] {
          padding-left: 45px;
          font-size: 12px;
        }

        &.active-toc {
          color: #00729d;
          font-weight: bold;
          background: linear-gradient(to right, #fff, #f3f5f2);
        }

        &:not(:last-child) {
          border-bottom: 1px solid #d8d8d8;
        }

        &:hover {
          color: #000;
        }
      }
    }
  }

  // General Styles
  font-family: 'Roboto', sans-serif;
  color: #3a3a3a;

  .post-content {
    line-height: 1.5;
  }

  .need-to-know,
  .all-reviews {
    margin: 20px 0;
    padding: 15px;
    border: 2px solid #3a3a3a;

    h1 {
      margin-top: 0;
    }
  }

  .need-to-know-ul {
    list-style-type: none;
    padding-left: 0;
  }

  .all-reviews {
    .review-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #3a3a3a;

      .review-slide {
        width: 100%;
      }

      .review-link {
        margin-left: auto;

        a {
          display: block;
          color: #fff;
          background: #a18c78;
          padding: 4px 7px;
          text-decoration: none;
          border-radius: 7px;
        }
      }
    }
    .review-stars {
      color: #ffd700;
    }
  }

  #FWbutton a.FWbutton {
    color: white;
  }
  [id*='gallery-'] {
    display: flex;
    flex-wrap: wrap;

    figure {
      width: 30%;
      margin: 10px 0;
    }

    @media (max-width: 480px) {
      figure {
        width: 50%;
      }
    }
  }

  h1 {
    font-weight: 500;
    font-size: 39px;
    line-height: 1em;

    &.title {
      font-size: 49px;
    }
  }

  h2 {
    margin: 26px 0 26px 0;
    font-size: 31px;
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
  }

  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2em;
    margin-bottom: 0;
  }

  p {
    font-size: 18px;
    line-height: 28.8px;
    text-indent: 0;
    margin: 0 0 26px 0;
  }

  a {
    color: #00729d;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
  a.visit-button {
    font-size: 12px;
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    color: #fff;
    background-color: #3691ce;
    border-color: #3691ce;
    text-decoration: none;
    cursor: pointer;
  }
  a.home-fw-button {
    color: white;
    text-decoration: none;
    font-family: sans-serif;
  }

  a.featureTitle {
    text-decoration: none;
    background-color: transparent;
    font-weight: 100;
    color: #2f2e33;
    text-align: left;
    margin: 20px 0;
    font-family: sans-serif;
    font-size: xx-large;
    font-weight: 900;
    &:hover {
      transition: color 0.15s ease-in;
      transform: scale(1.005);
      color: red;
    }
  }

  ul,
  ol {
    font-size: 18px;
    line-height: 28.8px;

    li {
      margin-bottom: 5px;
    }
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    padding: 10px;
  }

  img.homeFW-image {
    display: block;
    padding: 0px;
    background-color: transparent;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center center;
    height: 275px;
    object-fit: contain;
  }

  iframe {
    max-width: 100%;
    height: 450px;

    @media (max-width: 767px) {
      height: 400px;
    }

    @media (max-width: 480px) {
      height: 300px;
    }
  }

  figure {
    margin: 50px auto;
    max-width: 100%;
    text-align: center;

    figcaption {
      text-align: center;
      font-style: italic;
      font-weight: bold;
    }
  }

  table {
    width: 100% !important;
    border-collapse: collapse;
    border-spacing: 0;
  }
  table.full {
    width: 100%;
    margin-bottom: 1em;
  }
  .table-wrap {
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  th {
    font-weight: bold;
  }
  th,
  td {
    text-align: left;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
  }

  .maxbutton {
    display: block;
    width: 100%;
    max-width: 160px;
    padding: 10px 5px;
    margin: auto;
    color: #fff;
    text-decoration: none;
    background-color: #81d742;
    border: 2px solid #505ac7;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 0px #333;
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 35px;
      &.title {
        font-size: 45px;
      }
    }
    h2 {
      font-size: 29px;
    }
  }

  @media (max-width: 767px) {
    margin-top: -15px;

    h1 {
      font-size: 32px;
      &.title {
        font-size: 42px;
      }
    }

    h2 {
      font-size: 28px;
    }

    .flex-col-8 {
      flex-basis: 75%;
      max-width: 75%;
    }

    .toc-side {
      flex-basis: 25%;
      max-width: 25%;

      .right-wrapper {
        display: none;
      }

      .recent-posts-wrapper {
        margin-bottom: 15px;

        h3 {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 26px;
      &.title {
        font-size: 28px;
      }
    }
    h2 {
      font-size: 22px;
    }
    p,
    ul,
    ol {
      font-size: 16px;
    }

    .post-details {
      .author {
        font-size: 10px;
      }
    }

    .flex-col-8,
    .toc-side {
      padding-left: 5px;
      padding-right: 5px;
    }

    .toc-side {
      .recent-posts-wrapper {
        h3 {
          font-size: 17px;
          margin-top: 0;
        }

        ul {
          font-size: 14px;
          padding-left: 0;
          line-height: 1.4;

          li {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  // Post Details

  .post-details {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        color: #9b9b9b;
        border-radius: 6px;
        border: 2px solid #e4e4e4;

        a {
          padding: 0 4px;
          display: block;
          color: inherit;
        }

        &:hover {
          border-color: #9b9b9b;
        }
      }

      a {
        text-decoration: none;
      }
    }

    li {
      font-size: 14px;
    }

    & > li {
      &:first-of-type {
        border-top: 1px solid #c5c5c5;
      }
      padding: 10px 0 10px 5px;
      border-bottom: 1px solid #c5c5c5;
    }

    // author
    .author {
      display: flex;
    }

    .author-link {
      margin-left: 5px;
      margin-right: 15px;
    }

    // Social Share
    .social-share {
      display: flex;
      margin-left: auto;
      padding-left: 5px;
      align-items: center;

      .social-links {
        display: flex;
        margin-left: 5px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        color: #fff;
        font-size: 17px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }

      .facebook {
        background: #3960b1;
      }

      .twitter {
        background: #23abff;
      }

      .linkedin {
        background: #139fd3;
      }
    }

    @media (max-width: 380px) {
      .author {
        flex-wrap: wrap;
      }

      .social-share {
        padding-left: 0;
        width: 100%;
      }
    }
  }
`
const BlogPostTemplate = ({
  all_posts,
  content,
  categories,
  tags,
  title,
  date,
  author,
  uri,
}) => {
  const tableOfContents =
    (content.match(/<div id="toc_container"(.*)/gi) &&
      content.match(/<div id="toc_container"(.*)/gi)[0]) ||
    ''

  let filteredContent = content
    .replace(/<div id="toc_container"(.*)/gi, '')
    .replace(
      /href="https:\/\/reftactical.wpengine.com\/blog\/tag\//gi,
      'href="/blog/tags/'
    )
    .replace(
      /href="https:\/\/wp.refactortactical.com\/blog\//gi,
      'href="/blog/'
    )
    .replace(
      /src="https:\/\/reftactical.wpengine.com/gi,
      'src="https://wp.refactortactical.com'
    )
    .replace(/a href="https:\/\/reftactical.wpengine.com/gi, 'a href="')
    .replace(/\r?\n|\r/g, '') // remove line breaks/
    .replace(
      /\[\s*useful_resources[^\]]*\](.*?)\[\s*\/\s*useful_resources\]/gi,
      ''
    )
    .replace(/\[\s*article_summary[^\]]*\]/gi, '')

  const anchors = tableOfContents.match(/<a href.*?<\/a>\s*/gi) || []
  const filteredAd = filteredContent.includes('[dealoftheday')
  const filteredVW = filteredContent.includes('[bm-visitlink')
  const filteredFW = filteredContent.includes('[featured')

  let final = []
  if (filteredFW || filteredVW || filteredAd) {
    final = ShortcodeHandler(filteredContent)
  }

  const anchorsObj = anchors.map((anchor) => {
    const hash = anchor.match(/#.*?"/gi)[0].slice(0, -1)
    const text = anchor.match(/>(.*)</gi)[0].replace(/>|</gi, '')

    return { hash, text }
  })

  const TOCComponent =
    (anchorsObj.length > 0 && (
      <div className="toc-container">
        <div className="toc-inner">
          <h2>Table of Contents</h2>
          <ul>
            {anchorsObj.map((anchor, index) => {
              return (
                <li
                  key={index}
                  data-anchor={anchor.hash}
                  onClick={() => navigate(anchor.hash)}
                  dangerouslySetInnerHTML={{ __html: anchor.text }}
                ></li>
              )
            })}
          </ul>
        </div>
      </div>
    )) ||
    null

  const { url: siteUrl } = useSiteMetadata()
  const postUrl = `${siteUrl}${uri}`

  // Recent Posts
  const recent_posts_array = all_posts
    .map(({ node }) => node)
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
    .slice(0, 10)

  return (
    <PostTemplate className="post-template">
      <Global
        styles={css`
          body {
            &.lockdown-green-looks-good-on-you-sweepstakes {
              .post-details {
                & + div {
                  display: none;
                }
              }
              main {
                & > section {
                  & > .container {
                    & > .flex-row {
                      & > .center-div {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      />
      <div className="container">
        <div className="flex-row">
          <div className={`flex-col-8`}>
            <AdGroup
              slug="homepage-banner-top"
              carousel={true}
              carouselDelay={8000}
            />
            <h1 className="title" dangerouslySetInnerHTML={{ __html: title }} />
            <WidgetArticleSummary postContent={content} />
            <ul className="post-details">
              <li className="author">
                by{' '}
                <span className="author-link">
                  <Link to={`/blog/author/${author.node.slug}/`}>
                    {author.node.name}
                  </Link>
                </span>
                <span>{date}</span>
                <div className="social-share">
                  <span>Share on:</span>
                  <div className="social-links">
                    <a
                      className="facebook"
                      href={`https://www.facebook.com/sharer.php?u=${postUrl}`}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a
                      className="twitter"
                      href={`https://twitter.com/intent/tweet?text=${title}&url=${postUrl}&via=REFTactical`}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      className="linkedin"
                      href={`https://www.linkedin.com/shareArticle?url=${postUrl}&title=${title}`}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </div>
                </div>
              </li>
              {categories && categories.length ? (
                <li className="tags">
                  <ul>
                    {categories.map((category) => (
                      <li key={`${category.slug}cat`}>
                        <Link to={`/blog/categories/${category.slug}/`}>
                          {category.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : null}
              {tags && tags.length ? (
                <li className="tags">
                  <ul>
                    {tags.map((tag) => (
                      <li key={`${tag.slug}tag`}>
                        <Link to={`/blog/tags/${tag.slug}/`}>{tag.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ) : null}
            </ul>
            <div>
              <AdGroupHandler style="Blog Banners" />
            </div>
            {final.length ? (
              final.map((content, index) => (
                <Filter content={content} key={index} />
              ))
            ) : (
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: filteredContent }}
              />
            )}
          </div>
          <div className="flex-col-4 toc-side">
            <div className="ad-banner-sidebar">
              <AdGroup slug="post-page-right-banner" />
            </div>
            <div className="right-wrapper">
              {content.includes('[useful_resources') && (
                <WidgetUsefulResources postContent={content} />
              )}
              {TOCComponent}
            </div>
            <div className="recent-posts-wrapper">
              <h3>Recent Posts</h3>
              <ul>
                {recent_posts_array.map((recent_post, index) => (
                  <li key={index}>
                    <Link
                      to={`/blog/${recent_post.slug}/`}
                      title={recent_post.title}
                    >
                      {recent_post.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="center-div">
            <AdGroupHandler style="Blog Banners" />
          </div>
        </div>
      </div>
    </PostTemplate>
  )
}

function handleTOCScroll() {
  return
  const scrollOffset = this.pageYOffset

  tocScrollRef.forEach((li) => {
    if (scrollOffset >= li.start && scrollOffset <= li.end) {
      if (document.querySelectorAll('.active-toc').length === 0) {
        document
          .querySelector(`[data-anchor="${li.id}"]`)
          .classList.add('active-toc')
      } else if (currentActive.dataset.anchor !== li.id) {
        currentActive.classList.remove('active-toc')
        document
          .querySelector(`[data-anchor="${li.id}"]`)
          .classList.add('active-toc')
      }

      currentActive = document.querySelector(`.active-toc`)
    }
  })
}

const BlogPost = ({ data, pageContext: { head_tags } }) => {
  const { wpPost: post } = data
  const [commentState, setComment] = useState([])

  useEffect(() => {
    // reload issue fix
    if (
      !document.querySelector('.toc-side') &&
      document.querySelector('.flex-col-8 + .home-fw')
    ) {
      const errorCol = document.querySelector('.flex-col-8 + .home-fw')

      errorCol.setAttribute('class', 'flex-col-4 toc-side')

      // check if there's a table of contents, if so, add classes that belonged to it before breaking
      if (errorCol.textContent?.toLowerCase().includes('table of contents')) {
        errorCol
          .querySelector('.fw-flex')
          ?.setAttribute('class', 'toc-container active')

        errorCol.querySelector('.fw-price')?.setAttribute('class', 'toc-inner')
      }
    }

    const tocElemLi = document.querySelectorAll(`.toc-inner li`)
    const tocElemLength = tocElemLi.length
    window.currentActive = null
    // Comments
    fetch(
      `https://wp.refactortactical.com/wp-json/wp/v2/comments?post=${post.databaseId}&per_page=100`
    )
      .then((result) => result.json())
      .then((commentsJSON) => {
        let formattedComments = []
        if (commentsJSON.length) {
          formattedComments = commentsJSON
            .filter((comment) => comment.status === 'approved')
            .map(
              ({
                parent,
                author_name,
                author_url,
                content,
                date,
                post,
                status,
                id,
              }) => {
                let parent_element = null

                if (parent !== 0) {
                  parent_element = {
                    author_name: commentsJSON.filter(
                      (comment_parent) => comment_parent.id === parent
                    )[0].author_name,
                    wordpress_id: parent,
                  }
                }

                return {
                  node: {
                    author_name,
                    author_url,
                    content: content.rendered,
                    date,
                    parent_element,
                    post,
                    status,
                    wordpress_id: id,
                  },
                }
              }
            )
          setComment(formattedComments)
        }
      })

    if (tocElemLength === 0) return // setting up indentation
    ;[...document.querySelectorAll('.post-content span[id]')].forEach(
      (elem) => {
        const id = `#${elem.id}`
        const nodeName = elem.parentNode.nodeName.toLowerCase()

        if (document.querySelector(`li[data-anchor="${id}"`))
          document
            .querySelector(`li[data-anchor="${id}"`)
            .setAttribute('data-note-name', nodeName)
      }
    )

    document.querySelector('.toc-container').classList.add('active')

    // scroll and active on TOC
    window.tocScrollRef = []
    ;[...tocElemLi].forEach((li, index) => {
      return
      const id = li.dataset.anchor
      const nextIndex = index + 1
      const offsetX = document.querySelector(
        `[id="${id.replace('#', '')}"]`
      ).offsetTop
      let offsetXEnd

      if (nextIndex !== tocElemLength) {
        const idNext = [...tocElemLi][nextIndex].dataset.anchor
        offsetXEnd = document.querySelector(
          `[id="${idNext.replace('#', '')}"]`
        ).offsetTop
      } else {
        offsetXEnd = document.body.offsetHeight
      }

      tocScrollRef.push({
        id: id,
        start: offsetX - 30,
        end: offsetXEnd - 0.001,
      })
    })

    window.addEventListener('scroll', handleTOCScroll)

    return () => {
      window.removeEventListener('scroll', handleTOCScroll)
      delete window.currentActive
      delete window.tocScrollRef
    }
  }, [])

  return (
    <Layout>
      <PostSEO title={post.title} head={head_tags} />
      <PostContext.Consumer>
        {({ allWpPost: { edges: posts } }) => (
          <BlogPostTemplate
            all_posts={posts}
            content={post.content}
            categories={post.categories}
            tags={post.tags}
            title={post.title}
            date={post.date}
            uri={post.uri}
            author={post.author}
          />
        )}
      </PostContext.Consumer>

      <Comments comments={commentState} postId={post.databaseId} />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wp__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      databaseId
      uri
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
          slug
        }
      }
    }
  }
`
