function filterDOD(final, filteredContent) {
  let finalArray = []
  if (final.length) {
    for (let i = 0; i < final.length; i++) {
      if (typeof final[i] === 'string') {
        let DODlist = final[i].match(/\[dealoftheday.*?\]/g)
        let string = final[i]
        if (DODlist) {
          for (let z = 0; z < DODlist.length; z++) {
            let check = string.match(/\[dealoftheday.*?\]/g)
            if (check) {
              const filteredDOD = string.match(/\[dealoftheday.*?\]/).index
              const DOD = string.match(/\[dealoftheday.*?\]/)
              const stringHtml = string.slice(0, filteredDOD)
              string = string.slice(filteredDOD + DOD[0].length, string.length)
              const collection = DOD[0].match(/collection=(.*?)\]/)
              const DODobject = {
                DODcollection: collection,
              }
              finalArray.push(stringHtml)
              finalArray.push(DODobject)
            } else {
              finalArray.push(string)
            }
          }
          finalArray.push(string)
        } else {
          finalArray.push(string)
        }
      }
      if (typeof final[i] !== 'string') {
        finalArray.push(final[i])
      }
    }
  } else {
    let DODlist = filteredContent.match(/\[dealoftheday.*?\]/g)
    let string = filteredContent
    if (DODlist) {
      for (let z = 0; z < DODlist.length; z++) {
        let check = string.match(/\[dealoftheday.*?\]/g)
        if (check) {
          const filteredDOD = string.match(/\[dealoftheday.*?\]/).index
          const DOD = string.match(/\[dealoftheday.*?\]/)
          const stringHtml = string.slice(0, filteredDOD)
          string = string.slice(filteredDOD + DOD[0].length, string.length)
          const collection = DOD[0].match(/collection=(.*?)\]/)
          const DODobject = {
            DODcollection: collection,
          }
          finalArray.push(stringHtml)
          finalArray.push(DODobject)
        } else {
          finalArray.push(string)
        }
      }
      finalArray.push(string)
    } else {
      finalArray.push(string)
    }
  }
  return finalArray
}

function filterVWS(final, filteredContent) {
  let finalArray = []
  if (final.length) {
    for (let i = 0; i < final.length; i++) {
      if (typeof final[i] === 'string') {
        let VWlist = final[i].match(/\[bm-visitlink.*?\]/g)
        let string = final[i]
        if (VWlist) {
          for (let z = 0; z < VWlist.length; z++) {
            let check = string.match(/\[bm-visitlink.*?\]/g)
            if (check) {
              const filteredVW = string.match(/\[bm-visitlink.*?\]/).index
              const VW = string.match(/\[bm-visitlink.*?\]/)
              const stringHtml = string.slice(0, filteredVW)
              string = string.slice(filteredVW + VW[0].length, string.length)
              const type = VW[0].match(/type=(.*?)\ /)
              const href = VW[0].match(/href=(.*?)\ /)
              const title = VW[0].match(/title=(.*?)\]/)
              const VWobject = {
                VWtype: type,
                VWhref: href,
                VWtitle: title,
              }
              finalArray.push(stringHtml)
              finalArray.push(VWobject)
            } else {
              finalArray.push(string)
            }
          }
          finalArray.push(string)
        } else {
          finalArray.push(string)
        }
      }
      if (typeof final[i] !== 'string') {
        finalArray.push(final[i])
      }
    }
  }
  if (!final.length) {
    let VWlist = filteredContent.match(/\[bm-visitlink.*?\]/g)
    let string = filteredContent
    if (VWlist) {
      for (let z = 0; z < VWlist.length; z++) {
        let check = string.match(/\[bm-visitlink.*?\]/g)
        if (check) {
          const filteredVW = string.match(/\[bm-visitlink.*?\]/).index
          const VW = string.match(/\[bm-visitlink.*?\]/)
          const stringHtml = string.slice(0, filteredVW)
          string = string.slice(filteredVW + VW[0].length, string.length)
          const type = VW[0].match(/type=(.*?)\ /)
          const href = VW[0].match(/href=(.*?)\ /)
          const title = VW[0].match(/title=(.*?)\]/)
          const VWobject = {
            VWtype: type,
            VWhref: href,
            VWtitle: title,
          }
          finalArray.push(stringHtml)
          finalArray.push(VWobject)
        } else {
          finalArray.push(string)
        }
      }
      finalArray.push(string)
    } else {
      finalArray.push(string)
    }
  }
  return finalArray
}

function filterFWS(final, stringHtmlEnd, listFW) {
  for (let i = 0; i < listFW.length; i++) {
    const filteredFeature = stringHtmlEnd.match(/\[featured.*?\]/).index
    const Feature = stringHtmlEnd.match(/\[featured.*?\]/)
    const stringHtml = stringHtmlEnd.slice(0, filteredFeature)
    stringHtmlEnd = stringHtmlEnd.slice(
      filteredFeature + Feature[0].length,
      stringHtmlEnd.length
    )

    const widgetObject = {
      image: Feature[0].match(/image=(.*?)\ /),
      title: Feature[0].match(/title=(.*?)\ cost/),
      cost: Feature[0].match(/cost=(.*?)\ /),
      vendor: Feature[0].match(/vendor=("|”|″)(.*?)("|”|″) /),
      url: Feature[0].match(/url=(.*?)\]/),
    }
    final.push(stringHtml, widgetObject)
  }
  final.push(stringHtmlEnd)
  return final
}

function ShortcodeHandler(filteredContent) {
  const sanitizedFilteredContent = filteredContent
    .replace(/"|”|“|″|&#8221;|&#8243;/gi, '"')
    .replace(/&amp;|&#038;/gi, '&')
  let listFW = sanitizedFilteredContent.match(/\[featured.*?\]/g)
  let listVW = sanitizedFilteredContent.match(/\[bm-visitlink.*?\]/g)
  let listDOD = sanitizedFilteredContent.match(/\[dealoftheday.*?\]/g)
  let final = []
  let stringHtmlEnd = sanitizedFilteredContent

  if (listFW) {
    final = filterFWS(final, stringHtmlEnd, listFW) // to watch
  }
  if (listVW && final.length) {
    final = filterVWS(final, filteredContent)
  }
  if (listVW && !final.length) {
    final = filterVWS(final, filteredContent)
  }
  if (listDOD && final.length) {
    final = filterDOD(final, filteredContent)
  }
  if (listDOD && !final.length) {
    final = filterDOD(final, filteredContent)
  }

  return final
}

export default ShortcodeHandler
